// SCSS to beautify all scrollbars

// Define the custom scrollbar width and color variables
$scrollbar-width: 10px;
$scrollbar-color: #888888;

// Apply the styles to all elements
*::-webkit-scrollbar {
  width: $scrollbar-width;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: darken($scrollbar-color, 10%);
}

*::-moz-scrollbar {
  width: $scrollbar-width;
}

*::-moz-scrollbar-track {
  background-color: transparent;
}

*::-moz-scrollbar-thumb {
  background-color: $scrollbar-color;
}

*::-moz-scrollbar-thumb:hover {
  background-color: darken($scrollbar-color, 10%);
}

*::-ms-scrollbar {
  width: $scrollbar-width;
}

*::-ms-scrollbar-track {
  background-color: transparent;
}

*::-ms-scrollbar-thumb {
  background-color: $scrollbar-color;
}

*::-ms-scrollbar-thumb:hover {
  background-color: darken($scrollbar-color, 10%);
}
