.searchLabel{
    height: 100vh;
    width: 320px;
    background-color: #fff;
    // display: block;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    left: 320px;
    transition: left 1s ease-in-out; /* Add transition property */
    box-shadow: 4px 0 4px rgba(0, 0, 0, 0.2); /* Add box-shadow property */
    
    h1 {
      display: grid;
      place-items: center;
      height: 120px;
      font-size: 1.5rem;
      font-weight: 700;
    }
  
    form {
      display: flex;
      flex-direction: column;
      padding: 2rem;
  
      div {
        margin-bottom: 1rem;
  
        label {
          font-size: 1rem;
          font-weight: 500;
          color: #555555;
        }
  
        input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ddd;
          border-radius: 4px;
          margin-top: 0.75rem;
        }
      }
  
      button {
        margin-top: 1rem;
        padding: 0.5rem;
        background: #308efe;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background: #45a049;
        }
  
        &:disabled {
          background: #999999;
          cursor: not-allowed;
        }
      }
    }
  
    div {
      margin-top: 2rem;
  
      h2 {
        font-size: 1.25rem;
        font-weight: 500;
      }
  
      p {
        margin-top: 0.5rem;
      }
    }
  }
  