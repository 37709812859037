.Screen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  width: 100%;
  overflow: hidden !important;
  height: 100vh !important;

  &__content {
    width: 100%;
    height: 100vh !important;
    overflow-y: scroll !important;
    padding-left: 320px;
  }

  &__sidebar_content {
    padding-left: 335px;
    width: 100%;
    height: 100vh !important;
    overflow-y: scroll !important;
  }
}

.ticket {
  display: flex;
  flex-direction: column;
  overflow-y: scroll !important;
  margin-left: 320px;
  height: 100vh !important;
  background-color: #fff;
  align-items: center;
  justify-content: space-around;
  padding: 100px;

  h1 {
    display: grid;
    place-items: center;
    height: 120px;
    font-size: 1.5rem;
    font-weight: 700;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    width: 500px;

    div {
      margin-bottom: 1rem;

      label {
        font-size: 1rem;
        font-weight: 500;
        color: #555555;
      }

      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-top: 0.75rem;
      }
    }

    button {
      margin-top: 1rem;
      padding: 0.5rem;
      background: #308efe;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background: #45a049;
      }

      &:disabled {
        background: #999999;
        cursor: not-allowed;
      }
    }
  }
}
