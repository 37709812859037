.bg{
    width: 100%;
    height: 100vh;
  }
  
.login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    left: 50;
    top: 50;
    height: 500px;
  }

  .login-container h2 {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .login-container input {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #dddddd70;
    background-color: #fff;
    border-radius: 4px;
    margin: auto;
    font-size: medium;
  }
  
  .login-container button {
    width: 200px;
    padding: 10px;
    background: #308efe;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
    font-size: medium;
    margin-top: 40px;
  }
  
  .login-container button:hover {
    background: #45a049;
  }
  