


.labels {
    padding: 20px;
    background-color: #f4f4f4;
    display: flex;
    // flex-direction: row;
    width: 100%;
    margin-top: 70px;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  .fileList {
    display: flex;
    // flex-wrap: wrap;
    // width: 30px !important;
    // height: 20px;
    justify-content: space-around;
    flex-direction: row;
    width: inherit;
  }
  
  .fileItem {
    position: relative;
    // margin: 10px;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    margin: 40px;
  }
  
  .selected {
    border-color: #007bff;
  }
  
  .fileImage {
     width: 350px !important;
    // height: 300px;
    max-height: 150px;
    object-fit: contain;
    margin: 10px;

  }
  
  .fileName {
    display: block;
    text-align: center;
    font-weight: bold;
  }
  
  .buttonContainer {
    margin-top: 20px;
    text-align: center;
  }
  
  .printButton {
    padding: 10px 20px;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 999 !important;
  }
  
  .printButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  body {
    // background-color: #f4f4f4;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  