@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-image: url('./office.jpg') !important;
   
    background-repeat: no-repeat;
    background-size: cover;
    color: #2b2b2b;
    font-family: 'Roboto', sans-serif;
    font-size: medium;
}

a {
    text-decoration: none;
    color: unset;
}

